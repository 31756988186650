.App {
  padding-top: 100px;
  height: 100%;
  width: 100%;
  text-align: center;
  max-width: 1440px;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#title {
  height: 80px;
  width: 100%;
  text-align: left;
  font-size: 72px;
  font-family: 'Permanent Marker', cursive;
  color: #D6B081;
}

#description {
  margin-top: 50px;
  display: block;
  font-size: 20px;
  font-family: 'Cantarell', sans-serif;
  color: #E1E1E1;
  text-align: left;
}

#description span{
  font-size: 36px;
  font-weight: bold;
  color: #A4C2B6;
}

#feature{
  width: 100%;
  text-align: left;
  color: #D6B081;
  font-size: 24px;
  font-family: 'Cantarell', sans-serif;
  font-weight: bold;
}

.feature_line{
  width: 100%;
  height: 147px;
}

.feature_block{
  display: block;
  float: left;
  width: 200px;
  height: 97px;
  margin-top: 50px;
}
.not_last{
  margin-right: 40px;
}
.last{
  margin-right: 0px;
}

.panel-left{
  max-width: 680px;
  float:left;
  padding-right: 40px;
  margin-top: -20px;
}

.panel-right{
  max-width: 680px;
  float:left;
}

.panel-right img {
    max-width: 100%;
    height: auto;
}


#device{
  width: 100%;
  height: 100%;
}
#device img {
    max-width: 100%;
    max-height: 900px;
    height: auto;
}

#appstore {
  margin-top: 128px;
  text-align: left;
}

#appstore img{
  width: 220px;
  height: auto;
}
